// CreditRequestFormComponent.tsx

import React, { useEffect, useState } from 'react';
import FinancialSpreading from './utils/FinancialSpreading/FinancialSpreading';
import RatioAnalysis from './utils/RatioAnalysis/RatioAnalysis';
import RiskMatrix from './utils/RiskMatrix/RiskMatrix';
import { getRequest } from '../../../../../utils/handler/apiHandler';

interface CreditRequestFormComponentProps {
    FYCount: number;
    setFYCount: (cnt:number)=> void;
    isCalenderYear: boolean;
    creditRequestId: number;
    submitForm: boolean;
    setSubmitForm: (flag: boolean)=>void;
    assessmentFormFlag: number;
    setAssessmentFormFlag: (flagCnt: number)=>void;
    setShowMoveToCommitteeReview: (show: boolean)=>void;
    setShowDownloadCAM: (show: boolean)=>void;
    setShowDownloadSanctionLetter: (show: boolean)=>void;
    setShowEditDetail: (show: boolean)=>void;
    reloadComponentCnt: number;
    setReloadComponentCnt:(cnt: number)=>void;
    onHold: boolean;
}

const CreditRequestFormComponent: React.FC<CreditRequestFormComponentProps> = ({ FYCount, setFYCount, isCalenderYear, creditRequestId, submitForm, 
        setSubmitForm, assessmentFormFlag, setAssessmentFormFlag, setShowMoveToCommitteeReview, setShowDownloadCAM, setShowDownloadSanctionLetter, 
        setShowEditDetail, reloadComponentCnt, setReloadComponentCnt, onHold}) => {
    const [creditRequestData, setCreditRequestData] = useState<any>();
    
    useEffect(()=>{
        const fetchCreditRequestData = async ()=>{
            try{
                const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit?creditRequestId=${creditRequestId}`);
                setCreditRequestData(response.data);
                const buttonFlagDetail = JSON.parse(response.data?.buttonFlagDetail);
                setAssessmentFormFlag(buttonFlagDetail['Form flag'])
                
                setShowMoveToCommitteeReview(buttonFlagDetail['Move to committee review'] === 1 ? true : false);
                setShowDownloadCAM(buttonFlagDetail['Download CAM template'] === 1 ? true : false);
                setShowDownloadSanctionLetter(buttonFlagDetail['Download template'] === 1 ? true : false);
                setShowEditDetail(buttonFlagDetail['Edit detail'] === 1 ? true : false);
                const shareCapitalString = response.data?.financialSpreading?.shareCapital;
                const shareCapitalList = shareCapitalString ? JSON.parse(shareCapitalString) : [];
                if(shareCapitalList.length!==0){
                setFYCount(shareCapitalList.length);
                }

            }
            catch( error) {
                console.error('Error while fetching creditRequestData:', error);
             }
            }
        fetchCreditRequestData();
    },[reloadComponentCnt]);
    return (
        <>
            <FinancialSpreading  FYCount = {FYCount} isCalenderYear={isCalenderYear} creditRequestId={creditRequestId} assessmentFormFlag={assessmentFormFlag}
                financialSpreading={creditRequestData?.financialSpreading} submitForm={submitForm} setSubmitForm={setSubmitForm} setReloadComponentCnt={setReloadComponentCnt}/>
            { (assessmentFormFlag >2 || assessmentFormFlag === 2 && !onHold)  &&     
                <RatioAnalysis  FYCount = {FYCount} isCalenderYear={isCalenderYear} creditRequestId={creditRequestId} assessmentFormFlag={assessmentFormFlag} 
                    ratioAnalysis={creditRequestData?.ratioAnalysis} creditRequestData={creditRequestData} submitForm={submitForm} 
                    setSubmitForm={setSubmitForm} setReloadComponentCnt={setReloadComponentCnt}/>
            }
            { (assessmentFormFlag >3 || assessmentFormFlag === 3 && !onHold)  &&     
                <RiskMatrix creditRequestId={creditRequestId} assessmentFormFlag={assessmentFormFlag} riskMatrix={creditRequestData?.riskMatrix} creditRequestData={creditRequestData}
                    submitForm={submitForm} setSubmitForm={setSubmitForm} setReloadComponentCnt={setReloadComponentCnt}/>
            }
        </>
    );
};

export default CreditRequestFormComponent;
