import React, { Fragment } from "react";
import { Tabs, Typography } from "antd";
const TabPane = Tabs.TabPane;
import "../../../styles/Pages.sass";
import AllCreditAssessmentRequest from "./Assessment/AllCreditAssessmentRequest";
import InProgressCreditAssessmentRequest from "./Assessment/InProgressCreditAssessmentRequest";

function CreditAssessmentRequest() {
    return (
    <Fragment>
    <div className="parent-container">
        <div className="container-layout">
            <Typography style={{fontSize: 20, fontWeight: 700}}>Credit Assessment Requests</Typography>
            <div className="container-body">
            <Tabs defaultActiveKey="1" className="tab" onTabClick={(e) => console.log(e)}>
                <TabPane tab={<span className="tab-heading">All Assessments</span>} key="1">
                    <AllCreditAssessmentRequest />
                </TabPane>
                <TabPane tab={<span className="tab-heading">In progress</span>} key="2">
                    <InProgressCreditAssessmentRequest />
                </TabPane>
            </Tabs>
            </div>
        </div>
    </div>
    </Fragment>
    );
}

export default CreditAssessmentRequest;