import React, { useEffect, useState } from "react";
import { ReactComponent as NoDocuments } from '../../Prospect/utils/SVGs/NoDocuments.svg';
import { getRequest } from "../../../../utils/handler/apiHandler";
import { useLocation } from "react-router-dom";
import CreditAssessmentFormLayout from "./CreditAssessmentFormLayout";
import CreditAssessmentDetailsLayout from "./CreditAssessmentDetailsLayout";

interface CreditRequestDetailsProps {
    roleAccess: boolean;
    stageId: number;
}
const CreditRequestDetails: React.FC<CreditRequestDetailsProps> = ({ roleAccess, stageId }) => {
    const prospectId = useLocation().pathname.split("/").pop();
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        try {
            getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/prospectId?id=${prospectId}`).then(res => {
                setData(res.data);
            });
        } catch (err) {

        }
    }, []);
    return ( 
        <React.Fragment>
            { (data && (data?.creditRequestStatusMaster?.id === 1 || data?.creditRequestStatusMaster?.id === 8)) && <CreditAssessmentDetailsLayout roleAccess={roleAccess} /> }

            { (data && data.creditRequestStatusMaster.id === 2 || data && data.creditRequestStatusMaster.id === 3) && <CreditAssessmentFormLayout roleAccess={roleAccess} /> }

            { !data &&
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <NoDocuments />
                <p style={{fontSize:'20px', fontWeight:'500', color:'#5C5C5C', marginLeft:'2rem'}}> No Credit Request Available </p>
            </div> }
        </React.Fragment>
     );
}

export default CreditRequestDetails;