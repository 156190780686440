import { Button, Card, Dropdown, message, Modal, Select, Spin, Tag, Typography, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { getRequest, getRequestForDownload, handleFileDownload, postRequest, putRequest } from "../../../../utils/handler/apiHandler";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as TransferIcon } from '../../SVGs/transfer_icon.svg';
import { ReactComponent as DocumentIcon } from '../../SVGs/document_icon.svg';
import { ReactComponent as ReloadIcon } from '../../SVGs/reload_icon.svg';
import { ReactComponent as AddIcon } from '../../SVGs/add_icon.svg';
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import { ReactComponent as DownIcon } from '../../Prospect/utils/SVGs/DownIcon.svg';
import { ReactComponent as EditIcon } from '../../Prospect/utils/SVGs/edit_icon.svg';
import { ReactComponent as HollowDownloadIcon } from '../../Prospect/utils/SVGs/HollowDownloadIcon.svg';
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as HoldIcon } from '../../SVGs/hold_icon.svg';
import { ReactComponent as TickIcon } from '../../SVGs/tick_icon.svg';
import { ReactComponent as DownloadIcon } from '../../SVGs/download_icon.svg';
import { ReactComponent as UploadIcon } from '../../SVGs/upload_icon.svg';
import CreditAssessmentFormDetails from "./CreditAssessmentFormDetails";
import ParentAssessmentFrom from "./ParentAssessmentFrom";
import CreditRequestFormComponent from "../../Prospect/components/CreditRequest/CreditRequestFormComponent";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import Dragger from "antd/es/upload/Dragger";
import axios from "axios";
import { useAppSelector } from "../../../../app/hooks";
import { authSelector } from "../../../../features/auth/authSlice";
import { MenuProps } from "antd/lib/menu";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "#1677ff", marginLeft: 6 }} spin />;

interface CreditAssessmentFormLayoutProps {
    roleAccess: boolean;
}

const CreditAssessmentFormLayout: React.FC<CreditAssessmentFormLayoutProps> = ({ roleAccess }) => {
    const navigate = useNavigate();
    const auth = useAppSelector(authSelector);
    const prospectId = useLocation().pathname.split("/").pop();
    const [apiToggle, setApiToggle] = useState<any>(false);
    const [data, setData] = useState<any>(null);
    const [FYCount, setFYCount] = useState<number>(2); // FYCount 1-6 (max 6)
    const [changeFY, setChangeFY] = useState<boolean>(false); // isCalenderYear
    const [childView, setChildView] = useState<boolean>(true);
    const [creditFormModal, setCreditFormModal] = useState<boolean>(false);
    const [isParentAssessment, setIsParentAssessment] = useState<boolean>(false);
    const [creditRequestData, setCreditRequestData] = useState<any>();
    const [assessmentFormFlag, setAssessmentFormFlag] = useState<number>(1);
    const [showMoveToCommitteeReview, setShowMoveToCommitteeReview] = useState<boolean> (false);
    const [showDownloadCAM, setShowDownloadCAM] = useState<boolean> (false);
    const [showDownloadSanctionLetter, setShowDownloadSanctionLetter] = useState<boolean> (false);
    const [showEditDetail, setShowEditDetail] = useState<boolean> (false);
    const [submitForm, setSubmitForm] = useState<boolean> (false);
    const [reloadComponentCnt, setReloadComponentCnt] = useState<number> (0);
    const [editClickedCnt, setEditClickedCnt] = useState<number> (0);
    const [uploadCamModal, setUploadCamModal] = useState<boolean>(false)
    const [uploadLoader, setUploadLoader] = useState<boolean>(false);
    const [blob, setBlob] = useState<any>(null);
    const [generateCam, setGenerateCam] = useState<boolean>(false)
    const [holdLoader, setHoldLoader] = useState<boolean>(false);
    const [holdModal, setHoldModal] = useState<boolean>(false);
    const [file, setFile] = useState<any>(null);

    const items: MenuProps['items'] = [
    {
        label: <div style={{color: "#1677ff", fontWeight: 500, fontSize: 12}}><ReloadIcon style={{marginRight: 8}}/>Reupload CAM</div>,
        key: '1',
    },
    {
        label: <div style={{color: "#1677ff", fontWeight: 500, fontSize: 12}}><DownloadIcon style={{marginRight: 8}}/>Download Template</div>,
        key: '2',
    },
    ];

    useEffect(() => {
        try {
            getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/prospectId?id=${prospectId}`).then(res => {
                setData(res.data);
            });
        } catch (err) {

        }
    }, [apiToggle]);
    useEffect(()=>{
        if(editClickedCnt!==0){
            setReloadComponentCnt(Math.random());
            navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`)
            setTimeout(()=>window.location.reload(),2000);
            
        }
    },[editClickedCnt])

    const handleEditClick = async()=>{
        try{
            putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/buttonFlags/${data.creditRequestId}`,{}).then(res => {
                message.destroy();
            });
            
            setEditClickedCnt((oldVal:number)=>oldVal+1);
        }
        catch(err) {
            console.log("Error while handling Edit click : ", err);
        }
    }

    const handleDownloadCAM = async()=>{
        try{
            const response = await getRequestForDownload(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/report/downloadCam?creditRequestId=${data.creditRequestId}&isRegenerate=false`
            ,'application/docx');
            const url =  window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',  `downloadedDocumentId${data.creditRequestId}.docx`);
            document.body.appendChild(link);
            link.addEventListener('click', () => {
                setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                }, 100);
            });
            link.click();
            document.body.parentNode?.removeChild(link);
        }
        catch(err) {
            console.log("Error while handling DownloadCAM : ", err);
        }
    }

    const handleMarkHold = () => {
        const payload = {
            statusChangeTypeId : data.creditRequestStatusMaster.id !== 3 ? 3 : 2,
        }
        setHoldLoader(true);
        putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, payload).then(res => {
            setHoldLoader(false);
            setHoldModal(false);
            navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
            window.location.reload();
        })
    }
    
    const handleGenerateDownloadCAM = (num: number) => {
        try {
            handleFileDownload(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/report/downloadCam?creditRequestId=${data?.creditRequestId}`, `CAM_${data?.creditRequestId}`, `docx`).then((res: any) => {
                message.destroy();
                console.log(res)
                if(res === 200) {
                    navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                    !num && setTimeout(() => window.location.reload(), 1000);
                    setGenerateCam(false);
                    message.success("CAM downloaded")
                } else {
                    message.error("CAM download failed")
                }
            })
        } catch (err) {
            console.log(err);
            message.error("CAM download failed")
        }
    }

    const handleUpload = async () => {
        if(file) {
            setUploadLoader(true);
            try {
                let bodyFormData = new FormData();
                bodyFormData.append('file', file);
                bodyFormData.append('prospectId', data.prospectId);
                bodyFormData.append('creditRequestId', data?.creditRequestId);
    
                const res = await axios({
                    method: "post",
                    url: `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadCam`,
                    data: bodyFormData,
                    headers: { "Content-Type": "multipart/form-data", "Authorization": ` Bearer ${auth.accessToken}` },
                    });

                putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, {
                    camDocId: res.data.DocumentUrl
                }).then(res => {
                    message.destroy();
                    if(res.status === 200) { 
                        message.success("CAM uploaded successfully");
                        setUploadLoader(false);
                        navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                        setTimeout(() => window.location.reload(), 500)
                        }
                        setUploadCamModal(false);
                })
            } catch (err: any) {
                console.log("Error: ", err);
                message.error(err?.response?.data?.message);
                setUploadLoader(false)
            }
        }
    }

    const downloadTemplate = async () => {
        try {
            const response = await fetch(data?.camDocId);
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            const blobData: any = await response.blob();
            setBlob(blobData);
            
            // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
            const blobUrl = URL.createObjectURL(blobData);
            // Create a link element
            const link = document.createElement('a');
            // Set link's href to point to the Blob URL
            link.href = blobUrl;
            link.download = data?.camDocId.split("/").pop() || `CAM_${data?.creditRequestId}`;
            // Append link to the body
            document.body.appendChild(link);
            // Dispatch click event on the link
            // This is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(
                new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
                })
            );

            // Remove link from body
            document.body.removeChild(link);


        } catch (error) {
            console.error('Error converting S3 PDF to Blob:', error);
        }
    }

    const onClick: MenuProps['onClick'] = ({ key }) => {
        ~~key === 1
        ? setUploadCamModal(true)
        : downloadTemplate()
    };

    const handleView = () => {
        data?.isParentEntityAssessment
        ? putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, 
            {isParentEntityAssessment: false}).then(res => {
                message.destroy();
                navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                window.location.reload();
        })
        : setChildView(!childView);
    }
    return ( 
        <React.Fragment>
        {data && <>
            <span style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <div style={{display: "flex", alignItems: "center"}}>
                <Typography style={{fontSize: 14, fontWeight: 500}}>Credit Request</Typography>
                <Tag style={{marginLeft: 10, fontSize: 12, fontWeight: 500, color: data?.creditRequestStatusMaster?.color, backgroundColor: data?.creditRequestStatusMaster?.backgroundColor, border: "none"}}>{data.creditRequestStatusMaster?.name}</Tag>
                </div>
                <div>
                    {
                        !(data?.creditRequestStatusMaster?.id === 3) && 
                        <Button
                            ghost
                            type="primary"
                            onClick={_=> handleView()}
                            icon={<TransferIcon />}
                            style={{marginLeft: 10}}
                            className="button-override primary"
                        >{(data?.isParentEntityAssessment || !childView) ? "Switch to Child View" : "Switch to Parent View"}
                        </Button>
                    }
                    <Button
                        ghost
                        type="primary"
                        onClick={_=> {setCreditFormModal(true)}}
                        icon={<DocumentIcon />}
                        style={{marginLeft: 10}}
                        className="button-override primary"
                    >Show Credit Request Form
                    </Button>
                    { 
                    (childView && assessmentFormFlag === 1 && !(data?.creditRequestStatusMaster?.id === 3)) &&
                    <Button
                        ghost
                        type="primary"
                        disabled={!childView}
                        onClick={_=> setChangeFY(!changeFY)}
                        icon={<ReloadIcon />}
                        style={{marginLeft: 10}}
                        className={childView ? `button-override primary` : `button-override disable`}
                    >{ changeFY ? "Change to Financial Year" : "Change to Calendar Year"}
                    </Button>}
                    
                    {
                        (data && data.camDocId) &&
                        <Dropdown menu={{ items, onClick }} trigger={['click']}>
                        <Button
                        type="primary"
                        style={{fontWeight:'600', fontSize:'12px', marginLeft: 10, color:'#1777FF', backgroundColor: 'rgba(23, 119, 255, 0.10)', 
                        borderColor: '#1777FF'}}
                        className="button-override"
                        >
                            <div style={{display:'flex',}}>

                                <HollowDownloadIcon style={{marginTop:'2px', marginRight: 8, color:'#1777FF'}} />
                                <div style={{padding:'8px 10px 8px 0px' ,borderRight:"1px solid #1777FF", margin:'-8px 0px -8px 0px'}}>
                                    Download CAM 
                                </div>
                                <DownIcon style={{marginTop:'6px',marginLeft: 10, color:'#1777FF'}} />
                            </div>
                            
                            
                        </Button>
                        </Dropdown>
                    }

                    {
                        false &&
                        <Button
                        type="primary"
                        onClick={_=> {console.log(`Will Download SanctionLetter`)}}
                        className="button-override primary"
                        >
                            <HollowDownloadIcon style={{paddingTop:'1px', marginRight: 8, color:'#1777FF'}} />
                            <span >
                            Download Sanction Letter
                            </span>
                            
                        </Button>
                    }
                    {(childView && FYCount >2  && assessmentFormFlag === 1) &&
                    
                        <Button
                            ghost
                            danger
                            type="primary"
                            onClick={_=> setFYCount(FYCount-1)}
                            icon={<CrossIcon />}
                            style={{marginLeft: 10}}
                            className={!childView || FYCount <3  || assessmentFormFlag > 1 ? "button-override disable" : "button-override danger"}
                        >Remove Last FY
                        </Button>
                    }
                    
                    {(childView && FYCount < 5 && assessmentFormFlag === 1 && !(data?.creditRequestStatusMaster?.id === 3)) &&
                        <Button
                            ghost
                            type="primary"
                            onClick={_=> setFYCount(FYCount+1)}
                            icon={<AddIcon />}
                            style={{marginLeft: 10}}
                            className={childView && !(FYCount>=6) ? `button-override primary` : `button-override disable`}
                        >Add new FY
                        </Button>
                    }
                </div>
            </span>
            {
            childView 
                ? <CreditRequestFormComponent FYCount={FYCount} setFYCount={setFYCount} isCalenderYear={changeFY} creditRequestId={data.creditRequestId} 
                        submitForm={submitForm} setSubmitForm={setSubmitForm} assessmentFormFlag={assessmentFormFlag} setAssessmentFormFlag={setAssessmentFormFlag}
                        setShowMoveToCommitteeReview = {setShowMoveToCommitteeReview} setShowDownloadCAM = {setShowDownloadCAM}
                        setShowDownloadSanctionLetter = {setShowDownloadSanctionLetter} setShowEditDetail={setShowEditDetail}
                        reloadComponentCnt={reloadComponentCnt} setReloadComponentCnt={setReloadComponentCnt} onHold = {data?.creditRequestStatusMaster?.id === 3}
                    />
                : <ParentAssessmentFrom parentLegalBusiness={data?.parentLegalBusinessName} creditRequestId={data?.creditRequestId} setIsParentAssessment={setIsParentAssessment} setChildView={setChildView} />
            }
        
            {childView && <div style={{display: "flex", justifyContent: "flex-end", marginTop: 10}}>
                {
                    (showEditDetail && data?.creditRequestStatusMaster?.id !== 3 ) &&
                    <Button
                        type="primary"
                        onClick={_=> {
                           handleEditClick()
                            
                        }}
                        style={{fontWeight:'600', fontSize:'12px' ,marginLeft: 10, color:'#1777FF', backgroundColor: 'rgba(23, 119, 255, 0.10)', 
                        borderColor: '#1777FF'}}
                        className="button-override"
                    >
                        <EditIcon style={{marginRight: 8}} />
                        Edit Details
                       
                    </Button>
                }
                {
                (data && JSON.parse(data.buttonFlagDetail)?.["Move to committee review"] !== 1 && JSON.parse(data.buttonFlagDetail)?.["Mark on hold"] !== 0) &&
                <Button
                    ghost={data.creditRequestStatusMaster.id === 3 ? false : true}
                    type="primary"
                    onClick={_=> setHoldModal(true)}
                    icon={data.creditRequestStatusMaster.id !== 3 && <HoldIcon />}
                    style={{marginLeft: 10}}
                    className={ data.creditRequestStatusMaster.id === 3 ? "button-override" : "button-override secondary" }
                >
                { data.creditRequestStatusMaster.id === 3 ? "Continue Assessment Process" : "Mark On hold" }
                {data.creditRequestStatusMaster.id === 3 && <RightArrowIcon style={{marginLeft: 8}}/>}
                </Button>
                }
                {
                    ((data && !(JSON.parse(data.buttonFlagDetail)?.["Form flag"] >= 4)) && !(data?.creditRequestStatusMaster?.id === 3)) &&
                    <Button
                        type="primary"
                        onClick={_=> setSubmitForm(true)}
                        style={{marginLeft: 10}}
                        className="button-override"
                    >
                        Proceed
                        <RightArrowIcon style={{marginLeft: 8}} />
                    </Button>
                }
                {
                    (data && JSON.parse(data.buttonFlagDetail)?.["Move to committee review"] === 1) &&
                    <Button
                        type="primary"
                        onClick={_=> {
                           console.log(`Will move to committee review`)
                            
                        }}
                        style={{marginLeft: 10}}
                        className="button-override"
                    >
                        Move to Committee Review
                        <RightArrowIcon style={{marginLeft: 8}} />
                    </Button>
                }
                {
                    (data && JSON.parse(data.buttonFlagDetail)?.["Download CAM template"] === 1) &&
                    <Button
                        ghost
                        type="primary"
                        icon={<DownloadIcon />}
                        onClick={_=> handleGenerateDownloadCAM(1)}
                        style={{marginLeft: 10}}
                        className="button-override primary"
                    >
                        Download CAM template
                    </Button>
                }
                {
                    (data && JSON.parse(data.buttonFlagDetail)?.["Generate and download CAM"] === 1 && data?.creditRequestStatusMaster?.id !== 3) &&
                    <Button
                        type="primary"
                        style={{marginLeft: 10}}
                        onClick={_=> {setGenerateCam(true)} }
                        className="button-override"
                    >
                        {data?.downloadCamCount > 0 ? "Regenerate and download CAM" : "Generate and download CAM"}
                        <RightArrowIcon style={{marginLeft: 8}} />
                    </Button>
                }
                {
                    (data && JSON.parse(data.buttonFlagDetail)?.["Upload CAM"] === 1) &&
                    <Button
                        ghost
                        type="primary"
                        onClick={_=> setUploadCamModal(true)}
                        icon={<UploadIcon />}
                        style={{marginLeft: 10}}
                        className="button-override primary"
                    >
                        Upload CAM
                    </Button>
                }
            </div>}

        </>}

        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040", marginBottom: 20}}>Upload CAM</Typography>}
            centered
            closable={true}
            open={uploadCamModal}
            onCancel={() => setUploadCamModal(false)}
            afterClose={() => setFile(null)}
            footer={<>
                <div className="edit-section">
                    <Button
                        ghost
                        type="primary"
                        disabled={file === null}
                        onClick={handleUpload}
                        className={file === null ? "button-override disable" : "button-override primary"}
                        icon={uploadLoader ? <Spin indicator={antIcon} /> : <UploadIcon />}
                        >
                        Upload
                    </Button>
                </div>
            </>}
            className="preview-modal"
        >
            <Dragger
                maxCount={1}
                fileList={file && [file]}
                beforeUpload={(file) => { 
                    const allowedFileTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/msword']
                    setFile(file); 

                    console.log(file.type)
                    const isDocx = allowedFileTypes.includes(file.type);
                    if (!isDocx) {
                        message.error('This file format is not accepted!');
                        setFile(null);
                    }
                    return isDocx || Upload.LIST_IGNORE;
                }}
            >
                
                <p className="ant-upload-drag-icon">
                <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-text">Format accepted: </p>
                <Tag>pdf</Tag>
                <Tag>doc</Tag>
                <Tag>docx</Tag>
            </Dragger>
        </Modal>

        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040", marginBottom: 20}}>Credit Request Form</Typography>}
            centered
            closable={true}
            open={creditFormModal}
            onCancel={() => setCreditFormModal(false)}
            footer={<></>}
            width={"80%"}
            className="preview-modal"
        >
            <CreditAssessmentFormDetails />
        </Modal>
        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040", marginBottom: 20}}>{`Are you sure you want to ${ data?.creditRequestStatusMaster?.id === 3 ? "continue assessment process" : "hold"} this credit assessment?`}</Typography>}
            centered
            closable={false}
            open={holdModal}
            onCancel={() => setHoldModal(false)}
            footer={<>
                <Button
                    ghost
                    danger
                    icon={<CrossIcon />}
                    onClick={_=>setHoldModal(false)}
                    className="button-override danger"
                >
                    Cancel
                </Button>
                <Button
                    ghost
                    type="primary"
                    onClick={ handleMarkHold }
                    icon={holdLoader ? <Spin indicator={antIcon} /> : <TickIcon />}
                    className="button-override primary"
                >
                    Confirm
                </Button>
            </>}
            className="preview-modal"
        />
        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040", marginBottom: 20}}>{"Credit Assessment Memo"}</Typography>}
            centered
            closable={true}
            open={generateCam}
            onCancel={() => setGenerateCam(false)}
            footer={<>
                <Button
                    type="primary"
                    style={{padding: "10px 20px"}}
                    onClick={_=> handleGenerateDownloadCAM(0) }
                    className="button-override"
                >
                    Ok
                </Button>
            </>}
            className="preview-modal"
        >
            <Typography>A template for credit assessment memo has been downloaded on your system. You will have to upload the updated memo to continue the process.</Typography>
        </Modal>
        </React.Fragment>
     );
}

export default CreditAssessmentFormLayout;