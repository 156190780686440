import { Button, Col, message, Row, Spin, Typography, Upload } from "antd";
import { useEffect, useState } from "react";
import { authSelector } from "../../../features/auth/authSlice";
import { useAppSelector } from "../../../app/hooks";
import { DownloadOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";

export default function UploadRvRmty() {
    const [fileList, setFileList] = useState<any[]>([]);
    const auth = useAppSelector(authSelector);
    const [uploadLoader, setUploadLoader] = useState<boolean>(false);

    const antIconUpload = <LoadingOutlined style={{ fontSize: 16, color: "black", marginLeft: 6 }} spin />;

    const handleUploadRequest = async () => {
        setUploadLoader(true);
        const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/reportservice/dataUpload/rvRmty `;
        const formData = new FormData();
        formData.append('file', fileList[0]);
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log(response.data, "xxxxxx")
                if (response.status === 200) {
                    message.success(response.data)
                }
                else {
                    console.log("error in uploading file")
                }
                setUploadLoader(false);
                setFileList([])
            })
        } catch (error:any) {
            setUploadLoader(false);
            const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors[0]) ||
        'An error occurred while verifieng Max Lease';
            console.error('Upload error:', errorMessage);
            message.error(errorMessage)
        }
    };
    
    const handleDelete = (file: any) => {
        setUploadLoader(false);

        const tempFileList = [];
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i]?.uid !== file?.uid) {
                tempFileList.push(fileList[i]);
            }
        }
        setFileList(tempFileList);

    }

    return <div className="parent-container">
        <div className="container-layout">
            <Typography style={{ fontSize: 20, fontWeight: 700 }}>Upload RV & RMTY</Typography>
            <div style={{
                background: "white", padding: 20, borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
            }}>


                <Row>
                    <Col span={14}>
                        <Typography style={{fontSize: 18, fontWeight: 500}}>Please select an excel file to upload</Typography>
                        <Button
                            style={{ padding: 0, margin: 0 }}
                            type="link"
                            onClick={(e: any) => {
                                window.open("https://genevleasestatic.s3.ap-south-1.amazonaws.com/File-Template/sample/Tech-RV-RMTY-Template.xlsx", "_self")
                                
                            }}
                        >
                            Download Sample
                        </Button>
                    </Col>
                    <Col span={10}>
                        <Upload
                            // disabled={fileList.length > 0}
                            accept=".xlsx"
                            customRequest={handleUploadRequest}
                            beforeUpload={(file) => {
                                setFileList([file]);
                                console.log("upload file", file)
                                return false
                            }}
                            fileList={fileList}
                            onRemove={handleDelete}
                            listType="picture"
                            multiple
                        >
                            <Button
                                size="large"
                                type="dashed"
                                //  disabled={fileList.length > 0}
                                icon={uploadLoader ? <Spin indicator={antIconUpload} /> : <UploadOutlined />}
                                style={{ width: "472px" }}
                            >Upload RV & RMTY</Button>
                        </Upload>
                        
                    </Col>

                    <Col span={24}>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                            <Button loading={uploadLoader} onClick={handleUploadRequest} disabled={fileList.length == 0 || uploadLoader} style={{ paddingInline: 40 }} type="primary" size="large">Submit</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
}