import { useEffect, useState } from "react";
import { Button, Card, Divider, Form, Modal, Typography, UploadFile, message } from "antd";
import { ReactComponent as PipeIcon } from '../../../../utils/icons/Pipe_Icon.svg'; 
import { ReactComponent as CrossIcon } from '../../../../utils/icons/Cross_Icon.svg'; 
import { ReactComponent as CheckIcon } from '../../../../utils/icons/Check_Icon.svg'; 
import { ReactComponent as EditIcon } from '../utils/SVGs/edit_icon.svg';
import { ReactComponent as EyeIcon } from '../utils/SVGs/eye.svg';
import { ReactComponent as DocumentIcon } from '../utils/SVGs/document_icon.svg';
import { ReactComponent as DownloadIcon } from '../utils/SVGs/download_icon.svg';
import { ReactComponent as TickIcon } from "../utils/SVGs/tick_icon.svg";
import PreviewRequirementModal from "./PreviewRequirementModal";
import RequirementForm from "./RequirementForm";
import { getRequest, postRequest, putRequest } from "../../../../utils/handler/apiHandler";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import ConfirmPopup from "../../../../utils/ConfirmPopup";
import RequirementModal from "./RequirementModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReviseQuoteModal from "./ReviseQuoteModal";
import ReviseRemarkModal from "./ReviseRemarkModal";
import axios from "axios";
import { useAppSelector } from "../../../../app/hooks";
import { authSelector } from "../../../../features/auth/authSlice";

interface AddRequirementProps {
    prospectId: number;
    requirementNumber: number;
    setShowAddRequirement: (showAddRequirement: boolean) => void;
    formType: 'ADD' | 'EDIT' | 'DISPLAY';
    data: any;
    roleAccess: any;
    disableAddOrEditRequirement: boolean;
    setDisableAddOrEditRequirement: (disable: boolean) => void;
    stageId: number;
    prospectName: any;
}

const WON_STAGE_ID = 11, LOST_STAGE_ID = 10;
const notify = () => toast.success('Request for sample quote submitted successfully.');

const AddRequirement: React.FC<AddRequirementProps> = ({ prospectId, requirementNumber, setShowAddRequirement, formType, data, roleAccess, disableAddOrEditRequirement, setDisableAddOrEditRequirement, stageId, prospectName }) => {
    const auth = useAppSelector(authSelector);
    const [addRequirementsLoader, setAddRequirementsLoader] = useState(false);
    const [isConfirmRquirementVisible, setIsConfirmRquirementVisible] = useState(false);
    const [showVersionHistoryModal, setShowVersionHistoryModal] = useState(false);
    const [requirementPreviewModal, setRequirementPreviewModal] = useState(false);
    const [reviseQuoteModal, setReviseQuoteModal] = useState<boolean>(false);
    const [reviseRemarkModal, setReviseRemarkModal] = useState<boolean>(false);
    const [localFormType, setLocalFormType] = useState(formType);
    const [loader, setLoader] = useState(false);
    const [requestLoader, setRequestLoader] = useState(false);
    const [remark, setRemark] = useState("");
    const [quoteStatus, setQuoteStatus] = useState({id: null, backgroundColor: null, color: null, name: null});
    const [reviseRemarkLoader, setReviseRemarkLoader] = useState<boolean>(false);
    const [requirementId,setRequirementId] = useState(0);   
    const [fileList, setFileList] = useState<any>([]);
    const [allDocDetails, setAllDocDetails] = useState<any>([]);

    // console.log(quoteStatus);
    useEffect(() => {
        if(data) {
            setQuoteStatus(JSON.parse(data.quoteStatus));
        }
    }, []);
  
    const [form] = Form.useForm();
    const cities = useSelector((store: any) => store.quote.cities);
    const vehicles = useSelector((store: any) => store.quote.vehicles);
    const makers = useSelector((store: any) => store.quote.makers);
    const models = useSelector((store: any) => store.quote.models);
    const variants = useSelector((store: any) => store.quote.variants);
    const tenures = useSelector((store: any) => store.quote.tenures);
    const gevlStateList = useSelector((store: any) => store.quote.gevlStateList);
    const accessories = useSelector((store: any) => store.quote.accessories);
    const services = useSelector((store: any) => store.quote.services);

    const navigate = useNavigate();
    function onCancel(){
        setShowAddRequirement(false);
        setDisableAddOrEditRequirement(false);
        setLocalFormType('DISPLAY');
    }
    function onEdit(){
        setDisableAddOrEditRequirement(true);
        setLocalFormType('EDIT');
    }

    async function onSave(){
        const formValue = form.getFieldsValue(true);
        const accessoriesField = formValue.accessories?.map((accessoryId:any)=> generateObject(accessoryId,accessories));
        const servicesField = formValue.services?.map((serviceId:any)=> generateObject(serviceId,services));
        const tenureField = {
            id: formValue.tenure,
            name: tenures.find((item:any) => item.id == formValue.tenure)?.inMonth}
        const objForPutRequest = {
            vehicle: JSON.stringify(generateObject(formValue.vehicleId, vehicles)),
            make: JSON.stringify(generateObject(formValue.makeId, makers)),
            model: JSON.stringify(generateObject(formValue.modelId, models)),
            variant: JSON.stringify(generateObject(formValue.variantId, variants)),
            tenure:JSON.stringify(tenureField),
            accessories:JSON.stringify(accessoriesField),
            services:JSON.stringify(servicesField),
            stateId: formValue.gevlStateId,
            roadTaxType: formValue.roadTaxType,
            remark: formValue.remark
        };
        
        function generateObject(id:any, array:any) {
            const item:any = array.find((item:any) => item.id == id);
            return {
                id: id,
                name: item ? item.name : null
            };
        }
        let updatedFormValue = {
            ...form.getFieldsValue(true), // Create a copy of the form object
            ...objForPutRequest, // Merge objForPutRequest
            requirementNumber: 0
        };        
        delete updatedFormValue.vehicleId;
        delete updatedFormValue.makeId;
        delete updatedFormValue.modelId;
        delete updatedFormValue.variantId;
        delete updatedFormValue.gevlStateId;
        const payload={
            "requirements": [
                {...updatedFormValue},
            ]
        }
        try {
            await form.validateFields();
            setAddRequirementsLoader(true);
            let res;
            if(localFormType==="ADD")
                res = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospect/${prospectId}`,
                    payload);
            else res = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/requirement/${data.requirementId}`,
            updatedFormValue);
            navigate(`/dashboard/prospect/${prospectId}`);
            setTimeout(() => window.location.reload(), 1000);
            setAddRequirementsLoader(false);
        } catch (error) {
            // Handle error if any
            console.error("Error while Add Requirements:", error);
        }
        setShowAddRequirement(false);
        setDisableAddOrEditRequirement(false);
    }

    const handleConfirmRequirementModalConfirm = () => {
        setIsConfirmRquirementVisible(false);
        onSave();
    };

    const handleConfirmRequirementModalCancel = () => {
    setIsConfirmRquirementVisible(false);
    };

    const handleRequestSampleQuote = () => {
        setRequestLoader(true);
        try {
            postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/addQuoteRequest?prospectId=${prospectId}&requirementId=${data.requirementId}`, {}).then(res => {
                // message.success("Request for sample quote submitted successfully.");
                setRequestLoader(false);
                setRequirementPreviewModal(false);
                setTimeout(() => window.location.reload(), 1000)
                notify();
            });
        } catch (err) {
            console.log("Error: ", err);
        }
    }

    const handleRemark = async () => {
        console.log("in handle remark")
        console.log(allDocDetails)
        
        const allDocDTO = allDocDetails.map((item: any) => {
            const docURL=item.docURL;
            const fileName = docURL.split('/').pop();
            const contentType = fileName?.split('.').pop();
            return {
                fileName: fileName,
                contentType: contentType,
                revisionDocURL: docURL
            }
        })
        const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/addRequestRevisionDocDetails?requirementId=${data.requirementId}`;
        setReviseRemarkLoader(true);
        try {
            const response = await axios.post(url, allDocDTO, {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log('Upload successful:', response.data);
            postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/addQuoteRequest?prospectId=${prospectId}&requirementId=${data.requirementId}&isRevise=${1}`, {data: remark}).then(res => {
                setReviseRemarkModal(false);
                setReviseRemarkLoader(false);
                setRemark("");
                setTimeout(() => window.location.reload(), 500);
            })
        } catch(err) {
            console.log("Error: ", err)
        }
    }

    const handleUpload = () => {
        setRequirementId(data.requirementId);
    }

    const downloadQuotePreviewExcel = async () => {
        try {
          const res = await axios.post(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/report/excel/${data?.quoteId}`,
          {},
          {
              headers: {
                  "Authorization": `Bearer ${auth.accessToken}`
              },
              responseType: 'arraybuffer'
          }
          );
          const url =  window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${prospectName}_${data?.quoteId}.xlsx`);
          document.body.appendChild(link);
          link.addEventListener('click', () => {
              setTimeout(() => {
                  window.URL.revokeObjectURL(url);
              }, 100);
          });
          link.click();
          document.body.parentNode?.removeChild(link);
      } catch (error) {
          console.error('Error fetching dbData:', error);
      }
    }

    const handleFinaliseQuote = async(statusId:any) => {
        console.log(data)
        try{
            const payload : any = {
                statusChangeTypeId : statusId
            }
            const res = await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/quoteRequest/${data?.requirementId}`, payload);
            setTimeout(()=>window.location.reload(), 500);
            } 
        catch(err) {
            console.log("Error: ", err);
        }
    }

    return (
        <div style={{margin:'20px 0px',padding:'10px 20px 0px 20px', borderRadius:'8px', backgroundColor:'#FFFFFF'}}>
            <div className="header">
                <div style={{display: "flex", alignItems: "center"}}>
                <span style={{fontWeight:"bold"}}>
                    {`Requirement ${requirementNumber}`}
                </span>
                {requestLoader
                ?  <div style={{backgroundColor: "#80808010", color: "grey", padding: 8, borderRadius: 4, fontWeight: 500, fontSize: 12, marginLeft: 10}}>Sending request...</div>
                : quoteStatus.id && <div style={{backgroundColor: quoteStatus?.backgroundColor || "", color: quoteStatus?.color|| "", padding: 8, borderRadius: 4, fontWeight: 500, fontSize: 12, marginLeft: 10}}>{quoteStatus?.name}</div>}
                </div>

                {localFormType !== 'DISPLAY' && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            icon={<CrossIcon />}
                            style={{ padding: 5, color: '#FF4D4F', border: 'none', backgroundColor: 'transparent', boxShadow: 'none', fontSize: '12px' }}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <div style={{ marginRight: '5px', marginLeft: '5px' }}><PipeIcon /> </div>
                        <Button
                            icon={<CheckIcon />}
                            style={{ padding: 5, color: '#2C66E3', border: 'none', backgroundColor: 'transparent', boxShadow: 'none', fontSize: '12px' }}
                            onClick={() => {
                                // Trigger form validation
                                form
                                    .validateFields()
                                    .then(() => {
                                        // Validation passed, proceed with onSave logic
                                        setIsConfirmRquirementVisible(true)
                                    })
                                    .catch(errorInfo => {
                                        // Validation failed, handle error (if needed)
                                        console.error('Validation Error:', errorInfo);
                                    });
                            }}
                        >
                            Save
                        </Button>
                    </div>
                )}

                {localFormType === 'DISPLAY' && roleAccess && (
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Button
                            disabled={disableAddOrEditRequirement}
                            style={{ padding: 5, border: 'none', backgroundColor: 'transparent', boxShadow: 'none', fontSize: '12px', fontWeight: 500, color: disableAddOrEditRequirement ? 'grey' : '#2C66E3' }}
                            onClick={() => setShowVersionHistoryModal(true)}
                        >
                            <span style={{ color: disableAddOrEditRequirement ? 'grey' : '#2C66E3', marginRight:'0.4rem' }}>
                                <EyeIcon />
                            </span>
                            Show Version History
                        </Button>   
                        
                        {/* NEW REQUIREMENT STATE */}
                        {stageId === LOST_STAGE_ID
                        ? null
                        : !quoteStatus.id && <>
                            <div style={{ marginRight: '5px', marginLeft: '5px' }}><PipeIcon /> </div>
                            <Button
                                disabled={disableAddOrEditRequirement}
                                style={{ padding: 5, border: 'none', backgroundColor: 'transparent', boxShadow: 'none', fontSize: '12px', fontWeight: 500, color: disableAddOrEditRequirement ? 'grey' : '#2C66E3' }}
                                onClick={() => setRequirementPreviewModal(true)}
                            >
                                <span style={{ color: disableAddOrEditRequirement ? 'grey' : '#2C66E3', marginRight:'0.4rem' }}>
                                    <DocumentIcon />
                                </span>
                                Request Sample Quote
                            </Button>
                            <div style={{ marginRight: '5px', marginLeft: '5px' }}><PipeIcon /> </div>
                            <Button
                            disabled={disableAddOrEditRequirement}
                                style={{ padding: 5, border: 'none', backgroundColor: 'transparent', boxShadow: 'none', fontSize: '12px', fontWeight: 500, color: disableAddOrEditRequirement ? 'grey' : '#2C66E3' }}
                                onClick={onEdit}
                            >
                                <span style={{ color: disableAddOrEditRequirement ? 'grey' : '#2C66E3', marginRight:'0.4rem' }}>
                                    <EditIcon />
                                </span>
                                Edit
                            </Button>
                        </>}
                        {/* COMPLETE STATE */}
                        {stageId === LOST_STAGE_ID
                        ? null
                        : quoteStatus.id == 5 && <>
                            <div style={{ marginRight: '5px', marginLeft: '5px' }}><PipeIcon /> </div>
                            <Button
                                disabled={disableAddOrEditRequirement}
                                style={{ padding: 5, border: 'none', backgroundColor: 'transparent', boxShadow: 'none', fontSize: '12px', fontWeight: 500, color: disableAddOrEditRequirement ? 'grey' : '#2C66E3' }}
                                onClick={_ => {setReviseQuoteModal(true)}}
                            >
                                <span style={{ color: disableAddOrEditRequirement ? 'grey' : '#2C66E3', marginRight:'0.4rem' }}>
                                    <DocumentIcon />
                                </span>
                                Revise Sample Quote
                            </Button>
                            <div style={{ marginRight: '5px', marginLeft: '5px' }}><PipeIcon /> </div>
                            <Button
                            disabled={disableAddOrEditRequirement}
                                style={{ padding: 5, border: 'none', backgroundColor: 'transparent', boxShadow: 'none', fontSize: '12px', fontWeight: 500, color: disableAddOrEditRequirement ? 'grey' : '#2C66E3' }}
                                onClick={downloadQuotePreviewExcel}
                            >
                                <span style={{ color: disableAddOrEditRequirement ? 'grey' : '#2C66E3', marginRight:'0.4rem' }}>
                                    <DownloadIcon />
                                </span>
                                Download Sample Quotes
                            </Button>
                            {/* <div style={{ marginRight: '5px', marginLeft: '5px' }}><PipeIcon /> </div> */}
                            {/* <Button
                                style={{ padding: 5, border: 'none', backgroundColor: 'transparent', boxShadow: 'none', fontSize: '12px', fontWeight: 500, color:'#02B51F' }}
                                onClick={()=>handleFinaliseQuote(7)}
                            >
                                <span style={{ color:'#02B51F', marginRight:'0.4rem' }}>
                                    <TickIcon />
                                </span>
                                Finalise Quote
                            </Button> */}
                        </>}

                        {/* CANALLED STATE/ REJECTED STATE */}
                        {stageId === LOST_STAGE_ID
                        ? null
                        : (quoteStatus.id == 6 || quoteStatus.id == 7) && <>
                            <div style={{ marginRight: '5px', marginLeft: '5px' }}><PipeIcon /> </div>
                            <Button
                                disabled={disableAddOrEditRequirement}
                                style={{ padding: 5, border: 'none', backgroundColor: 'transparent', boxShadow: 'none', fontSize: '12px', fontWeight: 500, color: disableAddOrEditRequirement ? 'grey' : '#2C66E3' }}
                                onClick={() => setRequirementPreviewModal(true)}
                            >
                                <span style={{ color: disableAddOrEditRequirement ? 'grey' : '#2C66E3', marginRight:'0.4rem' }}>
                                    <DocumentIcon />
                                </span>
                                Request Sample Quote
                            </Button>
                            <div style={{ marginRight: '5px', marginLeft: '5px' }}><PipeIcon /> </div>
                            <Button
                            disabled={disableAddOrEditRequirement}
                                style={{ padding: 5, border: 'none', backgroundColor: 'transparent', boxShadow: 'none', fontSize: '12px', fontWeight: 500, color: disableAddOrEditRequirement ? 'grey' : '#2C66E3' }}
                                onClick={onEdit}
                            >
                                <span style={{ color: disableAddOrEditRequirement ? 'grey' : '#2C66E3', marginRight:'0.4rem' }}>
                                    <EditIcon />
                                </span>
                                Edit
                            </Button>
                        </>}
                        
                    </div>
                )}
                
            </div>
            <Divider style={{margin: "10px 0"}}/>
            <RequirementForm form={form} formType={localFormType} data={data}/>
            <ConfirmPopup
                  visible={isConfirmRquirementVisible}
                  message={'Are you sure you want to submit the Requirement?'}
                  onClose={handleConfirmRequirementModalCancel}
                  onConfirm={handleConfirmRequirementModalConfirm}
              />
            {
                data && <RequirementModal data={data} showVersionHistoryModal={showVersionHistoryModal} setShowVersionHistoryModal={setShowVersionHistoryModal} prospectName={prospectName} />
            }
            {    data && <PreviewRequirementModal data={data} requirementPreviewModal={requirementPreviewModal} setRequirementPreviewModal={setRequirementPreviewModal} handleRequestSampleQuote={handleRequestSampleQuote} requestLoader={requestLoader} />
            }
            {
                data && <ReviseQuoteModal data={data} reviseQuoteModal={reviseQuoteModal} setReviseQuoteModal={setReviseQuoteModal} setReviseRemarkModal={setReviseRemarkModal} form={form} />
            }
            <ReviseRemarkModal remark={remark} setRemark={setRemark} reviseRemarkModal={reviseRemarkModal} setReviseRemarkModal={setReviseRemarkModal} handleRemark={handleRemark} reviseRemarkLoader={reviseRemarkLoader} handleUpload={handleUpload} requirementId={requirementId} setAllDocDetails={setAllDocDetails} allDocDetails={allDocDetails} prospectId={prospectId}/>

        <ToastContainer />
        </div>
    );
}

export default AddRequirement;