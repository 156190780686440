import { Button, Col, Divider, Modal, Spin, Tag, Typography, message } from "antd";
import { ReactComponent as DocumentIcon } from '../utils/SVGs/document_icon.svg';
import { ReactComponent as CrossIcon } from '../../../../utils/icons/Cross_Icon.svg'; 
import RequirementForm from "./RequirementForm";
import { postRequest, putRequest } from "../../../../utils/handler/apiHandler";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PreviewData from "./PreviewData";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface ReviseQuoteModalProps {
    data: any;
    form: any;
    reviseQuoteModal: boolean;
    setReviseQuoteModal: (reviseQuoteModal: boolean) => void;
    setReviseRemarkModal: (reviseRemarkModal: boolean) => void;
}

const ReviseQuoteModal: React.FC<ReviseQuoteModalProps> = ({ data, reviseQuoteModal, setReviseQuoteModal, setReviseRemarkModal, form }) => {
    const prospectId = useLocation().pathname.split("/").pop();
    const [update, setUpdate] = useState<boolean>(false)
    const cities = useSelector((store: any) => store.quote.cities);
    const vehicles = useSelector((store: any) => store.quote.vehicles);
    const makers = useSelector((store: any) => store.quote.makers);
    const models = useSelector((store: any) => store.quote.models);
    const variants = useSelector((store: any) => store.quote.variants);
    const tenures = useSelector((store: any) => store.quote.tenures);
    const gevlStateList = useSelector((store: any) => store.quote.gevlStateList);
    const accessories = useSelector((store: any) => store.quote.accessories);
    const services = useSelector((store: any) => store.quote.services);
    const [reviseLoader, setReviseLoader] = useState<boolean>(false);

    const handleReviseRequest = async () => {
        setReviseLoader(true);
        const formData = form.getFieldsValue(true);
        const accessoriesList = filterObject(formData.accessories, accessories);
        const servicesList = filterObject(formData.services, services);
        const makeList = filterId(formData.makeId, makers, "name");
        const modelList = filterId(formData.modelId, models, "name");
        const variantList = filterId(formData.variantId, variants, "name");
        const vehicleList = filterId(formData.vehicleId, vehicles, "name");
        const tenuresList = filterId(formData.tenure, tenures, "inMonth");

        const payload = {
            accessories: JSON.stringify(accessoriesList),
            cityId: formData.cityId,
            insuranceType: formData.insuranceType,
            leaseType: formData.leaseType,
            make: JSON.stringify(makeList[0]),
            mileage: formData.mileage,
            model: JSON.stringify(modelList[0]),
            otherAccessoriesDescription: formData.otherAccessoriesDescription,
            otherServicesDescription: formData.otherServicesDescription,
            paymentType: formData.paymentType,
            roadTaxType: formData.roadTaxType,
            registeredTo: formData.registeredTo,
            registrationType: formData.registrationType,
            services: JSON.stringify(servicesList),
            stateId: formData.gevlStateId,
            tenure: JSON.stringify(tenuresList[0]),
            variant: JSON.stringify(variantList[0]),
            vehicle: JSON.stringify(vehicleList[0]),
            vehicleCount: formData.vehicleCount,
            vehicleType: formData.vehicleType,
            requirementNumber: data.requirementNumber,
            remark: formData.remark,
        }

        try {
            putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/requirement/${data?.requirementId}`, payload).then(res => {
                message.destroy();
                if(res.status === 200) {
                    postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/addQuoteRequest?prospectId=${prospectId}&requirementId=${data.requirementId}&isRevise=${1}`, {}).then(res => {
                        setReviseQuoteModal(false);
                        setReviseLoader(true);
                        message.success("Revise quote request sent.")
                        setTimeout(() => window.location.reload(), 500)
                    })
                }
            });
        } catch(err) {
            console.log(err);
        }
    }

    const filterObject = (formAccessories: any, masterList: any) => {
        if(_.isArray(formAccessories)) {
            let commonObjects = masterList.filter((obj1: any) =>
                formAccessories.some((obj2: any) => obj1.id === obj2)
            );
            let list: any = [];
            commonObjects.map((e: any, i: any) => {
                list.push({
                    id: e.id,
                    name: e.name
                })
            })
            return list;
        }
    }

    const filterId = (id: any, masterList: any, key: any) => {
        let commonObjects = masterList.filter((obj1: any) =>
            obj1.id === id
        );
        let list: any = [];
        commonObjects.map((e: any, i: any) => {
            list.push({
                id: e.id,
                name: e[key]
            })
        })
        return list;
    }

    return ( 
        <>
        <Modal 
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040"}}>Revise Sample Quote</Typography>}
            centered
            open={reviseQuoteModal}
            afterClose={() => { setUpdate(false); setReviseLoader(false);}}
            onOk={() => setReviseQuoteModal(false)}
            onCancel={() => setReviseQuoteModal(false)}
            width={"80%"}
            className="preview-modal"
            footer={<>
                {
                    update
                    ? <div className="edit-section">
                        <Button
                            ghost
                            danger
                            type="primary"
                            icon={<CrossIcon />}
                            onClick={_ => setReviseQuoteModal(false)}
                            className="button-override danger"
                            >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            icon={reviseLoader ? <Spin indicator={antIcon} /> : <DocumentIcon />}
                            onClick={_=> !reviseLoader && handleReviseRequest() }
                            className="button-override"
                            >
                            Revise Sample Quote
                        </Button>
                    </div>
                    : <div className="edit-section">
                    <Button
                        ghost
                        type="primary"
                        icon={<DocumentIcon />}
                        onClick={_ => { setReviseQuoteModal(false); setReviseRemarkModal(true); }}
                        className="button-override primary"
                        >
                        Revise with remark
                    </Button>
                    <Button
                        ghost
                        type="primary"
                        icon={<DocumentIcon />}
                        onClick={_=> setUpdate(true)}
                        className="button-override primary"
                        >
                        Update Request
                    </Button>
                </div>
                }
            </>}
        >
            <Typography>Update the details of this Requirement before requesting sample quote</Typography>
            {
                update
                ? <RequirementForm form={form} formType={"EDIT"} data={data}/>
                : <>
                    <PreviewData data={data} />
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                    {
                        ((data?.accessories || data?.accessories) && ((_.isArray(JSON.parse(data?.accessories)) && JSON.parse(data?.accessories).length !== 0) || (_.isArray(JSON.parse(data?.services)) && JSON.parse(data?.services).length !== 0))) &&
                        <Divider orientation="left" orientationMargin={0}><span style={{fontSize: 14, fontWeight: 500, margin: 0}}>Accessories & Services</span></Divider>
                    }
                    { (data?.accessories && JSON.parse(data?.accessories).length !== 0) && <div className="requirement-card-info" style={{width: "49%"}}>
                        <Typography className="description-label">Accessories</Typography>
                        <Typography style={{fontSize: 14, minHeight: 15}}>
                            <span>{
                                JSON.parse(data?.accessories).map((e: any) => {
                                    return <Tag style={{marginBottom: 5}}>{e.name}</Tag>
                                })
                            }</span>
                        </Typography>
                    </div> }
                    { (data?.services && JSON.parse(data?.services).length !== 0) &&  <div className="requirement-card-info" style={{width: "49%"}}>
                        <Typography className="description-label">Services</Typography>
                        <Typography style={{fontSize: 14, minHeight: 15}}>
                            <span>{
                                JSON.parse(data?.services).map((e: any) => {
                                    return  <Tag style={{marginBottom: 5}}>{e.name}</Tag>
                                })
                            }</span>
                        </Typography>
                    </div> }

                    { data?.remark &&  <div className="requirement-card-info" style={{width: "100%"}}>
                        <Typography className="description-label">Remark</Typography>
                        <Typography style={{fontSize: 14, minHeight: 15}}>
                            <span>{
                                data?.remark
                            }</span>
                        </Typography>
                    </div> }
                    </div>
                </>
            }
        </Modal>
        </>
     );
}

export default ReviseQuoteModal;