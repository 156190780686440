// RiskMatrix.tsx

import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Input, Typography } from 'antd';
import { ReactComponent as ArrowDown } from '../../../../utils/SVGs/ArrowDown.svg';
import { ReactComponent as ArrowRight } from '../../../../utils/SVGs/ArrowRight.svg';
import { formatRiskMatrixFormData, formatUpdateRiskMatrixFormData } from '../formUtils';
import { sendRiskMatrixFormData, sendUpdateRiskMatrixFormData } from '../apiCalls';
import DebtToEquityRatioComponent from './DebtToEquityRatioComponent';
import DebtToCapitalRatioComponent from './DebtToCapitalRatioComponent';
import DebtToEBIDTARatioComponent from './DebtToEBIDTARatioComponent';
import DebtToServiceCoverageRatioComponent from './DebtToServiceCoverageRatioComponent';
import InterestCoverageRatioComponent from './InterestCoverageRatioComponent';
import CurrentRatioComponent from './CurrentRatioComponent';
import FreeCashFlowComponent from './FreeCashFlowComponent';
import { useLocation, useNavigate } from 'react-router-dom';

interface FinancialSpreadingProps {
    creditRequestId: number;
    assessmentFormFlag: number;
    riskMatrix: any;
    creditRequestData: any;
    submitForm: boolean;
    setSubmitForm: (flag: boolean)=>void;
    setReloadComponentCnt: (value:number) => void;
}

const RiskMatrix: React.FC<FinancialSpreadingProps> = ({ creditRequestId, assessmentFormFlag, riskMatrix, creditRequestData, submitForm, 
        setSubmitForm, setReloadComponentCnt }) => {
    const [collapseFinancialSpreading, setCollapseFinancialSpreading] = useState<boolean>(false);
    const [updateTotalCnt, setUpdateTotalCnt] = useState<number>(0);
    const [ebidta, setEbidta] = useState<number>(0);
    // const [netRevenue, setNetRevenue] = useState<number>(0);
    const [viewModeActive, setViewModeActive] = useState<boolean>(false);
    const FORM_ID: number = 3;
    
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const prospectId = useLocation().pathname.split("/").pop()
    const longTermBorrowings = creditRequestData?.financialSpreading?.longTermBorrowings;
    const shortTermBorrowings = creditRequestData?.financialSpreading?.shortTermBorrowings;
    const totalEquityJSON = creditRequestData?.financialSpreading?.totalEquity;
    const totalDebt = returnSum(longTermBorrowings?JSON.parse(longTermBorrowings):[], 
        shortTermBorrowings?JSON.parse(shortTermBorrowings):[]);
    const totalEquity = totalEquityJSON ? JSON.parse(totalEquityJSON)?.[0]: 0;
    const totalCurrentAssetsJSON = creditRequestData?.financialSpreading?.totalCurrentAssets;
    const totalCurrentLiabilitiesJSON = creditRequestData?.financialSpreading?.totalCurrentLiabilities;
    const totalCurrentAssets = totalCurrentAssetsJSON ? JSON.parse(totalCurrentAssetsJSON)?.[0]: 0;
    const totalCurrentLiabilities = totalCurrentLiabilitiesJSON ? JSON.parse(totalCurrentLiabilitiesJSON)?.[0]: 0;
    const debtToEquityRatio = totalDebt/totalEquity;
    const totalDebtAndTotalEquity = totalDebt+totalEquity;
    const debtToCapitalRatio = totalDebt/totalDebtAndTotalEquity;
    const currentRatio = totalCurrentAssets/totalCurrentLiabilities;
    const netRevenueJSON = creditRequestData?.financialSpreading?.netRevenue;
    const netRevenue = netRevenueJSON ? JSON.parse(netRevenueJSON)?.[0]: 0;
    const revenueCoeFromCreditForm = creditRequestData?.riskMatrix?.revenueCoe;
        

    useEffect(()=>{
        setUpdateTotalCnt(( oldValue:number ) => oldValue+1 );
        form.setFieldsValue(generateInitialValues(parsedRiskMatrix))
    },[riskMatrix]);
    useEffect(()=>{
        if(assessmentFormFlag && assessmentFormFlag !== FORM_ID){
            setViewModeActive(true);
        }
        else{   
            setViewModeActive(false);
        }
    },[assessmentFormFlag]);

    useEffect(()=>{
        if(submitForm===true && assessmentFormFlag === FORM_ID){
            setSubmitForm(false);
            onFinish();
        }
    },[submitForm])

    const onFinish = async () => {
        try {
            const values = await form.validateFields();
            if(riskMatrix?.id){
                const formattedData = formatUpdateRiskMatrixFormData(form.getFieldsValue(true));
                await sendUpdateRiskMatrixFormData(formattedData,riskMatrix?.id, creditRequestId);
                setCollapseFinancialSpreading(true);
                navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                setTimeout(() => window.location.reload(), 500);
            }
            else{
                const formattedData = formatRiskMatrixFormData(form.getFieldsValue(true));
                await sendRiskMatrixFormData(formattedData, creditRequestId);
                navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                setTimeout(() => window.location.reload(), 500);
            }
            setReloadComponentCnt(Math.random());
        } catch (error) {
            console.error('Error while sending form data:', error);
        }
    };

    function returnSum (lst1:any[],lst2:any[]){
        if(lst1 && lst2){
            return lst1[0]+lst2[0]
        }
        else return 0;
    }
    // Parse risk matrix data if available
const parsedRiskMatrix = riskMatrix ? {
    ...riskMatrix,
    cibilScore: JSON.parse(riskMatrix.cibilScore),
    totalLiabilities: totalDebt,
    totalShareholderEquity: totalEquity,
    debtToEquityRatio: debtToEquityRatio,
    totalDebt: totalDebt,
    totalDebtAndTotalEquity: totalDebtAndTotalEquity,
    debtToCapitalRatio: debtToCapitalRatio,
    // totalDebtEbitda: JSON.parse(riskMatrix.totalDebtEbitda),
    ebidta: JSON.parse(riskMatrix.ebidta),
    debtToEbidtaRatio: JSON.parse(riskMatrix.debtToEbidtaRatio),
    revenueCoe: netRevenue,
    operatingExp: JSON.parse(riskMatrix.operatingExp),
    currentDebtObligations: totalDebt,
    debtToServiceCoverageRatio: JSON.parse(riskMatrix.debtToServiceCoverageRatio),
    operatingIncomeEbidta: JSON.parse(riskMatrix.ebidta),
    interestExpenses: JSON.parse(riskMatrix.interestExpenses),
    interestCoverageRatio: JSON.parse(riskMatrix.interestCoverageRatio),
    currentAssets: totalCurrentAssets,
    currentLiabilities: totalCurrentLiabilities,
    currentRatio: currentRatio,
    cashFromOperations: JSON.parse(riskMatrix.cashFromOperations),
    capitalExpenditures: JSON.parse(riskMatrix.capitalExpenditures),
    freeCashFlow: JSON.parse(riskMatrix.freeCashFlow),
    // Add parsing for other fields as needed
} : undefined;

// Helper function to generate initial values for risk matrix
const generateInitialValues = (riskMatrix?: any) => ({
    cibilScore: riskMatrix && riskMatrix.cibilScore !== undefined ? riskMatrix.cibilScore : 0,
    totalLiabilities: totalDebt !== undefined ? totalDebt : 0,
    totalShareholderEquity: totalEquity !== undefined ? totalEquity : 0,
    debtToEquityRatio: debtToEquityRatio !== undefined ? debtToEquityRatio : 0,
    totalDebt: totalDebt !== undefined ? totalDebt : 0,
    totalDebtAndTotalEquity: totalDebtAndTotalEquity !== undefined ? totalDebtAndTotalEquity : 0,
    debtToCapitalRatio: debtToCapitalRatio !== undefined ? debtToCapitalRatio : 0,
    // totalDebtEbitda: riskMatrix && riskMatrix.totalDebtEbitda !== undefined ? riskMatrix.totalDebtEbitda : 0,
    ebidta: riskMatrix && riskMatrix.ebidta !== undefined ? riskMatrix.ebidta : 0,
    debtToEbidtaRatio: riskMatrix && riskMatrix.debtToEbidtaRatio !== undefined ? riskMatrix.debtToEbidtaRatio : 0,
    revenueCoe: netRevenue !== undefined ? netRevenue : 0,
    operatingExp: riskMatrix && riskMatrix.operatingExp !== undefined ? riskMatrix.operatingExp : 0,
    currentDebtObligations: totalDebt !== undefined ? totalDebt : 0,
    debtToServiceCoverageRatio: riskMatrix && riskMatrix.debtToServiceCoverageRatio !== undefined ? riskMatrix.debtToServiceCoverageRatio : 0,
    operatingIncomeEbidta: riskMatrix && riskMatrix.operatingIncomeEbidta !== undefined ? riskMatrix.operatingIncomeEbidta : 0,
    interestExpenses: riskMatrix && riskMatrix.interestExpenses !== undefined ? riskMatrix.interestExpenses : 0,
    interestCoverageRatio: riskMatrix && riskMatrix.interestCoverageRatio !== undefined ? riskMatrix.interestCoverageRatio : 0,
    currentAssets: totalCurrentAssets !== undefined ? totalCurrentAssets : 0,
    currentLiabilities: totalCurrentLiabilities !== undefined ? totalCurrentLiabilities : 0,
    currentRatio: currentRatio !== undefined ? currentRatio : 0,
    cashFromOperations: riskMatrix && riskMatrix.cashFromOperations !== undefined ? riskMatrix.cashFromOperations : 0,
    capitalExpenditures: riskMatrix && riskMatrix.capitalExpenditures !== undefined ? riskMatrix.capitalExpenditures : 0,
    freeCashFlow: riskMatrix && riskMatrix.freeCashFlow !== undefined ? riskMatrix.freeCashFlow : 0,
    // Add defaults for other fields
});

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: '#FFF', padding:'20px',marginTop:'20px', borderRadius:'8px' }}>
                <div >
                    <span onClick={() => setCollapseFinancialSpreading((oldState) => !oldState)}>
                        {collapseFinancialSpreading ? <ArrowRight /> : <ArrowDown />}
                    </span>
                    <span style={{ color: '#242424', fontSize: '14px', fontWeight: '700', marginLeft: '10px' }}>
                        Risk Matrix
                    </span>
                </div>
                { !collapseFinancialSpreading && (
                    <>
                    <Form
                        form={form}
                        style={{ marginTop:'10px',borderRight: '1px #E8E8E8 solid', borderLeft: '1px #E8E8E8 solid', padding: '0px 7px 0px 7px' }}
                        onFinish={onFinish}
                    >
                        <Row gutter={16} style={{ backgroundColor: '#CEE6FA', padding: '15px 10px 15px 16px' }}>
                            <Col span={12} style={{fontWeight:'600'}}>Particulars</Col>
                            <Col span={12} style={{fontWeight:'600'}}> Values </Col>
                        </Row>
                        <Row gutter={16} style={{ paddingTop: '20px', borderBottom: '1px #E8E8E8 solid' }}>
                            <Col span={12} style={{fontWeight:'600', paddingLeft:'28px'}}>CIBIL Score</Col>
                            <Col span={12} style={{fontWeight:'600'}} key={1}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px', paddingBottom:'20px' }}>{form.getFieldValue('cibilScore')}</Typography>
                                ) : (
                                    <Form.Item
                                        name={"cibilScore"}
                                        rules={[{ required: true, message: 'Please enter Cibil Score' }]}
                                    >
                                        <Input
                                            type="number"
                                            placeholder='Cibil Score'
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                        <DebtToEquityRatioComponent form={form} updateTotalCnt={updateTotalCnt} viewModeActive={viewModeActive}/>
                        <DebtToCapitalRatioComponent form={form} updateTotalCnt={updateTotalCnt} viewModeActive={viewModeActive}/>
                        <DebtToEBIDTARatioComponent form={form} updateTotalCnt={updateTotalCnt} viewModeActive={viewModeActive} setEbidta={setEbidta}/>
                        <DebtToServiceCoverageRatioComponent form={form} updateTotalCnt={updateTotalCnt} viewModeActive={viewModeActive} netRevenue={netRevenue}/>
                        <InterestCoverageRatioComponent form={form} updateTotalCnt={updateTotalCnt} viewModeActive={viewModeActive} ebidta={ebidta}/>
                        <CurrentRatioComponent form={form} updateTotalCnt={updateTotalCnt} viewModeActive={viewModeActive}/>
                        <FreeCashFlowComponent form={form} updateTotalCnt={updateTotalCnt} viewModeActive={viewModeActive}/>
                    </Form>
                    {/* {
                        !viewModeActive &&
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: '100px', marginRight: '10px' }}
                                onClick={onFinish}
                            >
                                Proceed
                            </Button>
                        </div>
                    } */}
                    </>
                )}
                
            </div>
        </>
    );
};

export default RiskMatrix;
