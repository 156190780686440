// apiFunctions.ts

import { getRequest, postRequest } from '../../../../utils/handler/apiHandler';
import { ProspectInput } from './ProspectTypes';

const INPROGRESS_IDS = [2,4];

const fetchStagesList = async (): Promise<{ data: any[]} | null> => {
  try {
    const res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/stages`);
    if (res.data) {
      return res.data;
    }
    return null; // Return null if data is missing in the response
  } catch (error) {
    console.error('Error fetching StagesList:', error);
    return null; // Return null in case of an error
  }
};

const fetchStatusList = async (): Promise<{ data: any[]} | null> => {
  try {
    const res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/doc-status`);
    if (res.data) {
      return res.data;
    }
    return null; // Return null if data is missing in the response
  } catch (error) {
    console.error('Error fetching StagesList:', error);
    return null; // Return null in case of an error
  }
};

const fetchCreditAssessmentStatusList = async (): Promise<{ data: any[]} | null> => {
  try {
    const res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/creditStatus`);
    if (res.data) {
      return res.data;
    }
    return null; // Return null if data is missing in the response
  } catch (error) {
    console.error('Error fetching CreditAssessmentStatusList:', error);
    return null; // Return null in case of an error
  }
};
const fetchInProgressCreditAssessmentStatusList = async (): Promise<{ data: any[]} | null> => {
  try {
    const res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/creditStatus?ids=${INPROGRESS_IDS}`);
    if (res.data) {
      return res.data;
    }
    return null; // Return null if data is missing in the response
  } catch (error) {
    console.error('Error fetching CreditAssessmentStatusList:', error);
    return null; // Return null in case of an error
  }
};

export const fetchQuoteStatusData = async (setStatusList: any) => {
  try {
    const statusListResponse: any = await fetchQuoteStatusList();
    if (statusListResponse) {
      setStatusList(statusListResponse);
    }
  } catch (error) {
    // Handle errors if any
    console.error('Error fetching data:', error);
  }
};

export const fetchTenureData = async (setTenureList: any) => {
  try {
    const tenureListResponse: any = await fetchTenureList();
    if (tenureListResponse) {
      setTenureList(tenureListResponse);
    }
  } catch (error) {
    // Handle errors if any
    console.error('Error fetching data:', error);
  }
};

const fetchQuoteStatusList = async (): Promise<{ data: any[]} | null> => {
  try {
    const res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/quoteStatus`);
    if (res.data) {
      return res.data;
    }
    return null; // Return null if data is missing in the response
  } catch (error) {
    console.error('Error fetching StagesList:', error);
    return null; // Return null in case of an error
  }
};

const fetchTenureList = async (): Promise<{ data: any[]} | null> => {
  try {
    const res = await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/tenures`, {});
    if (res.data) {
      return res.data;
    }
    return null; // Return null if data is missing in the response
  } catch (error) {
    console.error('Error fetching TenureList:', error);
    return null; // Return null in case of an error
  }
};

const fetchBusinessSegmentsList = async (): Promise<{ data: any[]} | null> => {
  try {
    const res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/segments?isHeader=1`);
    if (res.data) {
      return res.data;
    }
    return null; // Return null if data is missing in the response
  } catch (error) {
    console.error('Error fetching BusinessSegmentsList:', error);
    return null; // Return null in case of an error
  }
};

export const fetchProspectTableData = async (filterPayload: any, setData: any, setPageCount: any, setTotalRecord: any, setLoading: any): Promise<void> => {
  try {
    setLoading(true); // Set loading to true before fetching data
    const res = await postRequest(
      `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospects`,
      filterPayload
    );
    if (res.status === 200) {
      setData(res.data.data);
      if ('pageCount' in res.data) {
        setPageCount(res.data.pageCount);
      }
      if ('totalRecord' in res.data) {
        setTotalRecord(res.data.totalRecord);
      }
    } else {
      console.error('Error fetching Prospects Table List:', res.msg);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    setLoading(false); // Set loading to false after data is fetched or in case of an error
  }
};

export const fetchBusinessAndStagesData = async (setStagesList: any, setBusinessSegmentsList: any) => {
  try {
    const stagesListResponse: any = await fetchStagesList();
    if (stagesListResponse) {
      setStagesList(stagesListResponse);
    }

    const businessSegmentsListResponse: any = await fetchBusinessSegmentsList();
    if (businessSegmentsListResponse) {
      setBusinessSegmentsList(businessSegmentsListResponse);
    }
  } catch (error) {
    // Handle errors if any
    console.error('Error fetching data:', error);
  }
};
export const fetchStatusData = async (setStatusList: any) => {
  try {
    const statusListResponse: any = await fetchStatusList();
    if (statusListResponse) {
      setStatusList(statusListResponse);
    } 
  } catch (error) {
    // Handle errors if any
    console.error('Error fetching data:', error);
  }
};
export const fetchCreditAssessmentStatusData = async (setStatusList: any) => {
  try {
    const statusListResponse: any = await fetchCreditAssessmentStatusList();
    if (statusListResponse) {
      setStatusList(statusListResponse);
    } 
  } catch (error) {
    // Handle errors if any
    console.error('Error fetching data:', error);
  }
};
export const fetchInProgressCreditAssessmentStatusData = async (setStatusList: any) => {
  try {
    const statusListResponse: any = await fetchInProgressCreditAssessmentStatusList();
    if (statusListResponse) {
      setStatusList(statusListResponse);
    } 
  } catch (error) {
    // Handle errors if any
    console.error('Error fetching data:', error);
  }
};


export const fetchQuoteNumDataFunction = async (searchKey: string, quoteNumCurrentPage: number, quoteNumPageSize: number, setQuoteNumList: Function, setQuoteNumCurrentPage: Function, setQuoteNumPageCount: Function, isInfiniteScroll: boolean) => {
  const quoteNumCurrentPageToBeFetched = isInfiniteScroll ? quoteNumCurrentPage - 1 : 0;
  try {
      const res = await getRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/quoteIds?page=${quoteNumCurrentPageToBeFetched}&size=${quoteNumPageSize}&number=${searchKey}`
      );
      if (res.status === 200) {
          if (isInfiniteScroll && quoteNumCurrentPage !== 1) {
            setQuoteNumList((prevItems:any) => [...prevItems, ...res.data.data]);
          } else {
            setQuoteNumList(res.data.data);
            setQuoteNumCurrentPage(1);
          }
          setQuoteNumPageCount(res.data.pageCount);
          return res.data.data;
      } else {
          console.error('Error fetching Quote Num List:', res.msg);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};

export const fetchRequestNumDataFunction = async (searchKey: string, requestNumCurrentPage: number, requestNumPageSize: number, setRequestNumList: Function, setRequestNumCurrentPage: Function, setRequestNumPageCount: Function, isInfiniteScroll: boolean) => {
  const requestNumCurrentPageToBeFetched = isInfiniteScroll ? requestNumCurrentPage - 1 : 0;
  try {
      const res = await getRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/requestNumbers?page=${requestNumCurrentPageToBeFetched}&size=${requestNumPageSize}&number=${searchKey}`
      );
      if (res.status === 200) {
          if (isInfiniteScroll && requestNumCurrentPage !== 1) {
              setRequestNumList((prevItems:any) => [...prevItems, ...res.data.data]);
          } else {
              setRequestNumList(res.data.data);
              setRequestNumCurrentPage(1);
          }
          setRequestNumPageCount(res.data.pageCount);
          return res.data.data;
      } else {
          console.error('Error fetching Request Num List:', res.msg);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};

export const fetchProspectNamesDataFunction = async (searchKey: string, prospectCurrentPage: number, prospectPageSize: number, setProspectsList: Function, setProspectCurrentPage: Function, setProspectPageCount: Function, isInfiniteScroll: boolean) => {
  const prospectCurrentPageToBeFetched = isInfiniteScroll ? prospectCurrentPage - 1 : 0;
  try {
      const res = await getRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospectNames?page=${prospectCurrentPageToBeFetched}&size=${prospectPageSize}&name=${searchKey}`
      );
      if (res.status === 200) {
          if (isInfiniteScroll && prospectCurrentPage !== 1) {
              setProspectsList((prevItems:any) => [...prevItems, ...res.data.data]);
          } else {
              setProspectsList(res.data.data);
              setProspectCurrentPage(1);
          }
          setProspectPageCount(res.data.pageCount);
          return res.data.data;
      } else {
          console.error('Error fetching Prospects Name List:', res.msg);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};

export const fetchRequirementDataFunction = async (searchKey: string, requirementCurrentPage: number, requirementPageSize: number, setRequirementList: Function, setRequirementCurrentPage: Function, setRequirementPageCount: Function, isInfiniteScroll: boolean) => {
  const requirementCurrentPageToBeFetched = isInfiniteScroll ? requirementCurrentPage - 1 : 0;
  try {
      const res = await getRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/requirementNumbers?page=${requirementCurrentPageToBeFetched}&size=${requirementPageSize}&number=${searchKey}`
      );
      if (res.status === 200) {
          if (isInfiniteScroll && requirementCurrentPage !== 1) {
            setRequirementList((prevItems:any) => [...prevItems, ...res.data.data]);
          } else {
            setRequirementList(res.data.data);
            setRequirementCurrentPage(1);
          }
          setRequirementPageCount(res.data.pageCount);
          return res.data.data;
      } else {
          console.error('Error fetching Requirement Number:', res.msg);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};

export const fetchBuyingCityDataFunction = async (searchKey: string, buyingCityCurrentPage: number, buyingCityPageSize: number, setBuyingCityList: Function, setBuyingCityCurrentPage: Function, setBuyingCityPageCount: Function, isInfiniteScroll: boolean) => {
  const buyingCityCurrentPageToBeFetched = isInfiniteScroll ? buyingCityCurrentPage - 1 : 0;
  try {
      const res = await getRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/cities_v2?page=${buyingCityCurrentPageToBeFetched}&size=${buyingCityPageSize}&name=${searchKey}`
      );
      if (res.status === 200) {
          if (isInfiniteScroll && buyingCityCurrentPage !== 1) {
            const cityList=res.data.data;
            cityList.map((e: any) => {
              delete e["active"];
              delete e["stateCode"];
              delete e["gstnStateCode"];
              e["name"] = e["cityName"];
            })
            setBuyingCityList((prevItems:any) => [...prevItems, ...cityList]);
          } else {
            const cityList=res.data.data;
            cityList.map((e: any) => {
              delete e["active"];
              delete e["stateCode"];
              delete e["gstnStateCode"];
              e["name"] = e["cityName"];
            })
            console.log(cityList)
            setBuyingCityList(cityList);
            setBuyingCityCurrentPage(1);
          }
          setBuyingCityPageCount(res.data.pageCount);
          return res.data.data;
      } else {
          console.error('Error fetching Buying City Name List:', res.msg);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};

export const fetchOwnerNamesDataFunction = async (searchKey: string, ownerCurrentPage: number, ownerPageSize: number, setOwnersList: Function, setOwnerCurrentPage: Function,setOwnerPageCount: Function, isInfiniteScroll: boolean) => {
  const ownerCurrentPageToBeFetched = isInfiniteScroll ? ownerCurrentPage - 1 : 0;
  try {
      const res = await getRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/owners?name=${searchKey}&page=${ownerCurrentPageToBeFetched}&size=${ownerPageSize}`
      );
      if (res.status === 200) {
          const tempOwnerList = res.data.data;
          if (isInfiniteScroll && ownerCurrentPage !== 1) {
              setOwnersList((prevItems:any) => [...prevItems, ...tempOwnerList]);
          } else {
              setOwnersList(tempOwnerList);
              setOwnerCurrentPage(1);
          }
          setOwnerPageCount(res.data.pageCount);
          return res.data.data;
      } else {
          console.error('Error fetching Owners Name List:', res.msg);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};

export const fetchMakeDataFunction = async (searchKey: string, makeCurrentPage: number, makePageSize: number, setMakeList: Function, setMakeCurrentPage: Function,setMakePageCount: Function, isInfiniteScroll: boolean) => {
  const makeCurrentPageToBeFetched = isInfiniteScroll ? makeCurrentPage - 1 : 0;
  try {
      const res = await getRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/make_v2?make=${searchKey}&page=${makeCurrentPageToBeFetched}&size=${makePageSize}`
      );
      if (res.status === 200) {
          const tempMakeList = res.data.data;
          if (isInfiniteScroll && makeCurrentPage !== 1) {
              setMakeList((prevItems:any) => [...prevItems, ...tempMakeList]);
          } else {
              setMakeList(tempMakeList);
              setMakeCurrentPage(1);
          }
          setMakePageCount(res.data.pageCount);
          return res.data.data;
      } else {
          console.error('Error fetching make List:', res.msg);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};

export const fetchModelDataFunction = async (searchKey: string, modelCurrentPage: number, modelPageSize: number, setModelList: Function, setModelCurrentPage: Function,setModelPageCount: Function, isInfiniteScroll: boolean) => {
  const modelCurrentPageToBeFetched = isInfiniteScroll ? modelCurrentPage - 1 : 0;
  try {
      const res = await getRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/model_v2?model=${searchKey}&page=${modelCurrentPageToBeFetched}&size=${modelPageSize}`
      );
      if (res.status === 200) {
          const tempModelList = res.data.data;
          if (isInfiniteScroll && modelCurrentPage !== 1) {
              setModelList((prevItems:any) => [...prevItems, ...tempModelList]);
          } else {
              setModelList(tempModelList);
              setModelCurrentPage(1);
          }
          setModelPageCount(res.data.pageCount);
          return res.data.data;
      } else {
          console.error('Error fetching model List:', res.msg);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};

export const fetchVariantDataFunction = async (searchKey: string, variantCurrentPage: number, variantPageSize: number, setVariantList: Function, setVariantCurrentPage: Function,setVariantPageCount: Function, isInfiniteScroll: boolean) => {
  const variantCurrentPageToBeFetched = isInfiniteScroll ? variantCurrentPage - 1 : 0;
  try {
      const res = await getRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/variant_v2?variant=${searchKey}&page=${variantCurrentPageToBeFetched}&size=${variantPageSize}`
      );
      if (res.status === 200) {
          const tempVariantList = res.data.data;
          if (isInfiniteScroll && variantCurrentPage !== 1) {
              setVariantList((prevItems:any) => [...prevItems, ...tempVariantList]);
          } else {
            setVariantList(tempVariantList);
            setVariantCurrentPage(1);
          }
          setVariantPageCount(res.data.pageCount);
          return res.data.data;
      } else {
          console.error('Error fetching model List:', res.msg);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};

export const fetchOwnerNamesDataFunctionForCreditAssessment = async (searchKey: string, ownerCurrentPage: number, ownerPageSize: number, setOwnersList: Function, setOwnerCurrentPage: Function, isInfiniteScroll: boolean) => {
  const ownerCurrentPageToBeFetched = isInfiniteScroll ? ownerCurrentPage - 1 : 0;
  try {
      const res = await getRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/owners?page=${ownerCurrentPageToBeFetched}&size=${ownerPageSize}&name=${searchKey}`
      );
      if (res.status === 200) {
          const tempOwnerList = res.data.data;
          if (isInfiniteScroll && ownerCurrentPage !== 1) {
              setOwnersList((prevItems:any) => [...prevItems, ...tempOwnerList]);
          } else {
              setOwnersList(tempOwnerList);
              setOwnerCurrentPage(1);
          }
          return res.data.data;
      } else {
          console.error('Error fetching Owners Name List for Credit:', res.msg);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};

export const fetchPickedByDataFunctionForCreditAssessment = async (searchKey: string, currentPage: number, pageSize: number, setList: Function, setCurrentPage: Function, isInfiniteScroll: boolean) => {
  const currentPageToBeFetched = isInfiniteScroll ? currentPage - 1 : 0;
  try {
      const res = await getRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/pickedByList?page=${currentPageToBeFetched}&size=${pageSize}&name=${searchKey}`
      );
      if (res.status === 200) {
          const tempList = res.data.data;
          if (isInfiniteScroll && currentPage !== 1) {
              setList((prevItems:any) => [...prevItems, ...tempList]);
          } else {
              setList(tempList);
              setCurrentPage(1);
          }
          return res.data.data;
      } else {
          console.error('Error fetching List for Picked By List for Credit Assessment:', res.msg);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};

export const fetchUpdatedByNamesDataFunction = async (searchKey: string, ownerCurrentPage: number, ownerPageSize: number, setOwnersList: Function, setOwnerCurrentPage: Function, isInfiniteScroll: boolean) => {
  const ownerCurrentPageToBeFetched = isInfiniteScroll ? ownerCurrentPage - 1 : 0;
  try {
      const res = await getRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/updatedUsers?page=${ownerCurrentPage-1}&size=${ownerPageSize}&name=${searchKey}`
      );
      if (res.status === 200) {
          const tempOwnerList = res.data.data;
          if (isInfiniteScroll && ownerCurrentPage !== 1) {
              setOwnersList((prevItems:any) => [...prevItems, ...tempOwnerList]);
          } else {
              setOwnersList(tempOwnerList);
              setOwnerCurrentPage(1);
          }
          return res.data.data;
      } else {
          console.error('Error fetching Owners Name List:', res.msg);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};

export const fetchUpdatedByNamesForCreditAssessmentDataFunction = async (searchKey: string, ownerCurrentPage: number, ownerPageSize: number, setOwnersList: Function, setOwnerCurrentPage: Function, isInfiniteScroll: boolean) => {
  const ownerCurrentPageToBeFetched = isInfiniteScroll ? ownerCurrentPage - 1 : 0;
  try {
      const res = await getRequest(
          `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/updatedByList?page=${ownerCurrentPage-1}&size=${ownerPageSize}&name=${searchKey}`
      );
      if (res.status === 200) {
          const tempOwnerList = res.data.data;
          if (isInfiniteScroll && ownerCurrentPage !== 1) {
              setOwnersList((prevItems:any) => [...prevItems, ...tempOwnerList]);
          } else {
              setOwnersList(tempOwnerList);
              setOwnerCurrentPage(1);
          }
          return res.data.data;
      } else {
          console.error('Error fetching Owners Name List:', res.msg);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};